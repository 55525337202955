import {Http} from "./http";

const MSH_URL = process.env.VUE_APP_METASHIP_API_URL;

const c = new Http(MSH_URL);

export class MshApi {
    get cli() {
        return c;
    }

    documents = {
        getApp(consignmentId) {
            return c.cli.get(`/documents/app-label/${consignmentId}`, {responseType: 'blob'});
        },
    }
}
