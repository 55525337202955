<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth" scrollable>
    <template v-slot:activator="{on}">
      <slot name="activator" v-bind="{on}"/>
    </template>
    <v-card class="pb-2">
      <v-card-title class="green mb-4">
        <span class="headline grey--text">{{ title(formModel) }}</span>
        <v-spacer/>
        <v-btn text fab @click="declined">
          <v-icon color="grey">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-4">
        <custom-form
            v-model="valid"
            :model="formModel"
            :fields="fields"
            @change="setFormModel"
        >
          <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
            <slot :name="slot" v-bind="props"/>
          </template>
        </custom-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn outlined :disabled="acceptButtonLoading" :loading="acceptButtonLoading" text @click="declined"
               color="primary">{{ btnTitles.declined }}
        </v-btn>
        <v-btn color="primary" :disabled="!valid || !externalValid" @click="confirmed">{{ btnTitles.confirmed }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomForm from "./CustomForm";

export default {
  name: "DialogForm",
  components: {
    CustomForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    btnTitles: {
      type: Object,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
    },
    title: {
      type: Function,
      default: model => 'Title',
    },
    maxWidth: {
      type: String,
      default: '600px',
    },
    fields: {
      type: Object,
      required: true,
    },
    closeOnAccept: {
      type: Boolean,
      default: true,
    },
    acceptButtonLoading: {
      type: Boolean,
    },
    externalValid: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      valid: false,
      datePikers: {},
    }
  },
  methods: {
    confirmed() {
      if (this.closeOnAccept) {
        this.dialog = false;
      }
      this.$emit('confirmed', this.formModel);
    },
    declined() {
      this.dialog = false;
      this.formModel = {};
      this.$emit('declined');
    },
    setFormModel(model) {
      this.formModel = model;
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formModel: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
}
</script>

<style scoped>

</style>
