import {Http, PublicHttp} from "./http";

const BASE_URL = process.env.VUE_APP_PROTECTED_API_URL;
const PUBLIC_BASE_URL = process.env.VUE_APP_PUBLIC_API_URL;

const c = new Http(BASE_URL);
const pc = new PublicHttp(PUBLIC_BASE_URL);

async function cdekWarningWrapper(promise) {
  const timeout = setTimeout(() => {
    window.app.$store.dispatch(
        "setGlobalErrorMessage",
        {
          message: "Работа с сдек замедлена. Запрос может занять некоторое время",
          type: "warning"
        }
    );
  }, 10000);

  return promise.finally(_ => {
    clearTimeout(timeout);
  });
}

export class AuthApi {

  get cli() {
    return c
  }

  addNewParcel = {

    createParcel(id) {
      return c.post(`/api/add/new/parcels/finalize?id=${id}`)
    },

    createParcelSingleStep(body) {
      return c.post(`/api/add/new/parcels/create`, body);
    },

    async getCost(body) {
      return cdekWarningWrapper(c.post(`/api/add/new/parcels/cost`, body));
    },

    getCountries() {
      return c.get(`/api/add/new/parcels/counties`)
    },

    getFirstStep(id) {
      return c.get(`/api/add/new/parcels/first/step?id=${id}`)
    },

    getFourthStep(id) {
      return c.get(`/api/add/new/parcels/fourth/step?id=${id}`)
    },

    getRegions(countryCode) {
      return c.get(`/api/add/new/parcels/regions?countryCode=${countryCode}`)
    },

    getSecondStep(id) {
      return c.get(`/api/add/new/parcels/second/step?id=${id}`)
    },

    getThirdStep(id) {
      return c.get(`/api/add/new/parcels/third/step?id=${id}`)
    },

    liatChecking() {
      return c.get(`/api/add/new/parcels/not/filled`)
    },

    listAllClientsMin(offset, limit) {
      return c.get(`/api/add/new/parcels/clients?offset=${offset ? offset : ''}&limit=${limit ? limit : ''}`)
    },

    listCities(regionCode) {
      return c.get(`/api/add/new/parcels/cities?regionCode=${regionCode}`)
    },

    listOffices(cityCode) {
      return c.get(`/api/add/new/parcels/offices?cityCode=${cityCode}`)
    },

    removeParcel(id) {
      return c.post(`/api/add/new/parcels/remove?id=${id}`)
    },

    saveFirstStep(body) {
      return cdekWarningWrapper(c.post(`/api/add/new/parcels/first/step`, body))
    },

    saveFourthStep(body) {
      return c.post(`/api/add/new/parcels/fourth/step`, body)
    },

    saveSecondStep(userId, id) {
      return c.post(`/api/add/new/parcels/second/step?userId=${userId}&id=${id}`)
    },

    saveThirdStep(body) {
      return c.post(`/api/add/new/parcels/third/step`, body)
    },

    searchCity(name, countryCode) {
      return c.get(`/api/add/new/parcels/search/city?name=${name}&countryCode=${countryCode}`)
    },

    verifyClient(verificationCode, body) {
      return c.post(`/api/add/new/parcels/verify?verificationCode=${verificationCode}`, body)
    }
  }

  addressStorage = {

    delete(id) {
      return c.delete(`/api/address/storage?id=${id}`)
    },

    list() {
      return c.get(`/api/address/storage`)
    },

    listIncoming() {
      return c.get(`/api/address/storage/incoming`)
    },

    listOutgoing() {
      return c.get(`/api/address/storage/outgoing`)
    },

    save(body) {
      return c.post(`/api/address/storage`, body)
    }
  }

  barcodeDict = {

    delete(id) {
      return c.delete(`/api/dict/barcode/${id}`)
    },

    listAllByStatus(status) {
      return c.get(`/api/dict/barcode?status=${status}`)
    },

    listFeeCount(consignmentId) {
      return c.get(`/api/dict/barcode/free/count?consignmentId=${consignmentId}`)
    },

    save(body) {
      return c.post(`/api/dict/barcode`, body)
    },

    saveInRange(body) {
      return c.post(`/api/dict/barcode/range`, body)
    },

    update(body) {
      return c.put(`/api/dict/barcode`, body)
    }
  }

  box = {

    delete(id) {
      return c.delete(`/api/dict/boxes/${id}`)
    },

    getByBarCode(barcode) {
      return c.get(`/api/dict/boxes/barcode?barcode=${barcode}`)
    },

    getById(id) {
      return c.get(`/api/dict/boxes/${id}`)
    },

    listAll() {
      return c.get(`/api/dict/boxes`)
    },

    save(body) {
      return c.post(`/api/dict/boxes`, body)
    },

    update(body) {
      return c.put(`/api/dict/boxes`, body)
    }
  }

  business = {

    block(id) {
      return c.post(`/api/business/users/block?id=${id}`)
    },

    create(body) {
      return c.post(`/api/business/users`, body)
    },

    getUserApiKey(id) {
      return c.get(`/api/business/users/byUserId?id=${id}`)
    },

    listAll() {
      return c.get(`/api/business/users`)
    }
  }

  businessTariff = {

    create(body) {
      return c.post(`/api/business/tariff`, body)
    },

    delete(id) {
      return c.post(`/api/business/tariff/delete/${id}`)
    },

    listAll() {
      return c.get(`/api/business/tariff`)
    }
  }

  cdek = {

    listAll() {
      return c.get(`/api/cdek/tariff`)
    },

    listDeliveryMode() {
      return c.get(`/api/cdek/tariff/delivery/mode`)
    },

    listParcelTypes() {
      return c.get(`/api/cdek/tariff/parcel/types`)
    },

    save(body) {
      return c.post(`/api/cdek/tariff`, body)
    }
  }

  consignments = {

    createOversizeContainer(parcelId, consignmentId) {
      return c.post(`/api/consignments/crate/oversize/container?parcelId=${parcelId}&consignmentId=${consignmentId}`)
    },

    delete(id) {
      return c.delete(`/api/consignments?id=${id}`)
    },

    getAwbInfo(id) {
      return c.get(`/api/consignments/get/awb?id=${id}`)
    },

    getById(id) {
      return c.get(`/api/consignments/byId?id=${id}`)
    },

    getByTrack(track) {
      return c.get(`/api/consignments/getByTrack?track=${track}`)
    },

    getContainerExcelByContainer(id) {
      return c.get(`/api/consignments/excel/container?id=${id}`)
    },

    getContainerExcelByPallet(id) {
      return c.get(`/api/consignments/excel/pallet?id=${id}`)
    },

    getExcel(id) {
      return c.get(`/api/consignments/excel?id=${id}`)
    },

    getExcelChecking(id) {
      return c.get(`/api/consignments/excel/checking?id=${id}`)
    },

    getRegistryById(id) {
      return c.post(`/api/consignments/registry/by?id=${id}`)
    },

    getNewRegistryById(id) {
      return c.cli.get(`/api/report/cdek/consignment/${id}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      })
    },

    list(offset, limit) {
      return c.get(`/api/consignments?offset=${offset ? offset : ''}&limit=${limit ? limit : ''}`)
    },

    listChecking() {
      return c.get(`/api/consignments/checking`)
    },

    listHistory(offset, limit) {
      return c.get(`/api/consignments/history?offset=${offset ? offset : ''}&limit=${limit ? limit : ''}`)
    },

    listSent() {
      return c.get(`/api/consignments/sent`)
    },

    putIntoHistory(id) {
      return c.post(`/api/consignments/history?id=${id}`)
    },

    save(body) {
      return c.post(`/api/consignments`, body)
    },

    send(id) {
      return c.post(`/api/consignments/send?id=${id}`)
    },

    setAwb(id, awb) {
      return c.post(`/api/consignments/set/awb?id=${id}&awb=${awb}`)
    },

    sendToGBS(id) {
      return c.post(`/api/gbs/upload-consignment/${id}`)
    },

    sendToMSH(id) {
      return c.post(`/api/msh/upload-consignment/${id}`)
    }
  }

  container = {

    addParcel(track, id) {
      return c.post(`/api/containers/add/parcel?track=${track}&id=${id}`)
    },

    deleteContainer(id) {
      return c.delete(`/api/containers/${id}`)
    },

    findAllByConsignmentId(id) {
      return c.get(`/api/containers/consignments/${id}`)
    },

    findById(id) {
      return c.get(`/api/containers/${id}`)
    },

    getBarCode(id) {
      return c.get(`/api/containers/barcode/${id}`)
    },

    removeFromContainer(id) {
      return c.post(`/api/containers/remove/from/container?id=${id}`)
    },

    save(body) {
      return c.post(`/api/containers`, body)
    },

    search(val) {
      return c.get(`/api/containers/search?val=${val}`)
    },

    update(body) {
      return c.put(`/api/containers`, body)
    }
  }

  country = {

    delete(id) {
      return c.post(`/api/dict/country/delete/${id}`)
    },

    listAll() {
      return c.get(`/api/dict/country`)
    },

    save(body) {
      return c.post(`/api/dict/country`, body)
    },

    update(body) {
      return c.post(`/api/dict/country/update`, body)
    }
  }

  deliveryServices = {

    listHistory() {
      return c.get(`/api/delivery/services`)
    },

    update(id, active) {
      return c.post(`/api/delivery/services?id=${id}&active=${active}`)
    }
  }

  dict = {

    countries() {
      return c.get(`/api/dict/countries`)
    },

    offices(cityCode) {
      return c.get(`/api/dict/office?cityCode=${cityCode}`)
    },

    searchCity(name, countryCode) {
      return c.get(`/api/dict/search/city?name=${name}&countryCode=${countryCode}`)
    },

    stocks() {
      return c.get(`/api/dict/stock`)
    }
  }

  incomingParcels = {

    countsByTabs() {
      return c.get(`/api/incoming/parcels/counts-by-tabs`)
    },

    createComment(body) {
      return c.post(`/api/incoming/parcels/create/comment`, body)
    },

    getCommentsTypes() {
      return c.get(`/api/incoming/parcels/comments/types`)
    },

    getParcelsByFilter(body) {
      return c.post(`/api/incoming/parcels/filter`, body)
    },

    getSendingCompany() {
      return c.get(`/api/incoming/parcels/sending/company`)
    },

    getTrackByDeliveringTrack(track) {
      return c.get(`/api/incoming/parcels/track/by/delivering/track?track=${track}`)
    },

    listAll(page, limit) {
      return c.get(`/api/incoming/parcels?page=${page ? page : ''}&limit=${limit ? limit : ''}`)
    },

    listAllClientsMin(offset, limit) {
      return c.get(`/api/incoming/parcels/clients?offset=${offset ? offset : ''}&limit=${limit ? limit : ''}`)
    },

    listInStockWithErrors(solved, offset, limit) {
      return c.get(`/api/incoming/parcels/inStock/with-errors?solved=${solved}&offset=${offset ? offset : ''}&limit=${limit ? limit : ''}`)
    },

    register(body) {
      return cdekWarningWrapper(c.post(`/api/incoming/parcels/register`, body))
    },

    registerUndefined(body) {
      return cdekWarningWrapper(c.post(`/api/incoming/parcels/register/undefined`, body))
    },

    returnParcel(id) {
      return c.post(`/api/incoming/parcels/return?id=${id}`)
    },

    utilize(id) {
      return c.post(`/api/incoming/parcels/utilize?id=${id}`)
    }
  }

  invoice = {

    approve(invoiceId, value) {
      return c.post(`/api/business/invoice/${invoiceId}/approve/${value}`)
    },

    getHistory(dateFilter) {
      return c.get(`/api/business/invoice/history?dateFilter=${dateFilter}`)
    },

    getNew(dateFilter) {
      return c.get(`/api/business/invoice/new?dateFilter=${dateFilter}`)
    }
  }

  log = {

    listAllBySourceId(sourceId, source) {
      return c.get(`/api/logs/source/${sourceId}/${source}`)
    },

    save(body) {
      return c.post(`/api/logs`, body)
    }
  }

  menu = {

    menu() {
      return c.get(`/api/menu`)
    }
  }

  outgoingParcels = {

    countsByTabs() {
      return c.get(`/api/outgoing/parcels/counts-by-tabs`)
    },

    createComment(body) {
      return c.post(`/api/outgoing/parcels/create/comment`, body)
    },

    createParcelCollection(body) {
      return cdekWarningWrapper(c.post(`/api/outgoing/parcels/collect`, body))
    },

    getLabel(id) {
      return c.post(`/api/outgoing/parcels/label?id=${id}`)
    },

    getParcelsByFilter(body) {
      return c.post(`/api/outgoing/parcels/filter`, body)
    }
  }

  palette = {

    delete(id) {
      return c.delete(`/api/dict/palettes/${id}`)
    },

    getBarCode(id) {
      return c.get(`/api/dict/palettes/barcode/${id}`)
    },

    listAll() {
      return c.get(`/api/dict/palettes`)
    },

    save(body) {
      return c.post(`/api/dict/palettes`, body)
    },

    update(body) {
      return c.put(`/api/dict/palettes`, body)
    }
  }

  pallet = {

    addParcel(seal, id) {
      return c.post(`/api/pallets/add/container?seal=${seal}&id=${id}`)
    },

    delete(id) {
      return c.delete(`/api/pallets/${id}`)
    },

    getById(id) {
      return c.get(`/api/pallets/byId?id=${id}`)
    },

    listAllByConsignmentId(id) {
      return c.get(`/api/pallets?id=${id}`)
    },

    removeFromPallet(id) {
      return c.post(`/api/pallets/remove/from/pallet?id=${id}`)
    },

    save(body) {
      return c.post(`/api/pallets`, body)
    },

    update(body) {
      return c.put(`/api/pallets`, body)
    },

    updateDimensions(body) {
      return c.post(`/api/pallets/update/dimensions`, body)
    }
  }

  parcel = {

    getBarCode(id) {
      return c.post(`/api/parcel/getBarcode?id=${id}`)
    },

    getById(id) {
      return c.get(`/api/parcel/getById?id=${id}`)
    },

    getByIdOrExternalTrack(val) {
      return c.get(`/api/parcel/getByIdOrExternalTrack?val=${val}`)
    },

    getByIdOrTrackCode(val) {
      return c.get(`/api/parcel/getByIdOrTrackCode?val=${val}`)
    },

    getByTrack(track) {
      return c.get(`/api/parcel/getByTrack?track=${track}`)
    },

    getCdekNumberBarcode(id) {
      return c.post(`/api/parcel/getCdekNumberBarcode?id=${id}`)
    },

    getQr(id) {
      return c.post(`/api/parcel/getQr?id=${id}`)
    },

    getTransitType() {
      return c.get(`/api/parcel/transit/type`)
    },

    listAllByParcelId(id) {
      return c.get(`/api/parcel/status/updates/getById?id=${id}`)
    },

    update(body) {
      return c.post(`/api/parcel/update`, body)
    }
  }

  parcelManualError = {

    create(body) {
      return c.post(`/api/parcel-manual-error`, body)
    },

    delete(parcelManualErrorId) {
      return c.delete(`/api/parcel-manual-error/${parcelManualErrorId}`)
    },

    deleteMultiple(body) {
      return c.put(`/api/parcel-manual-error/delete/multiple`, body)
    },

    solve(parcelId, body) {
      return c.put(`/api/parcel-manual-error/parcel/${parcelId}/solve`, body)
    },

    types() {
      return c.get(`/api/parcel-manual-error/types`)
    },

    update(parcelManualErrorId, body) {
      return c.put(`/api/parcel-manual-error/${parcelManualErrorId}`, body)
    }
  }

  parcelRegistry = {

    deleteProduct(body) {
      return c.post(`/api/parcel/registry/delete/product`, body)
    },

    getById(id) {
      return c.get(`/api/parcel/registry/byId?id=${id}`)
    },

    getExcel(id) {
      return c.get(`/api/parcel/registry/excel?id=${id}`)
    },

    getExcelByCdekCodesFromCdek(body) {
      return c.post(`/api/parcel/registry/excel/by/cdek/numbers/fromCdek`, body)
    },

    getExcelById(body) {
      return c.post(`/api/parcel/registry/excel/by/id`, body)
    },

    getExcelPayments() {
      return c.post(`/api/parcel/registry/excel/payments`)
    },

    getRegistryByIdsAndTrackCodesAndCdekCodesAndUserStocks(body) {
      return c.post(`/api/parcel/registry/excel`, body)
    },

    list() {
      return c.get(`/api/parcel/registry`)
    },

    listCountry() {
      return c.get(`/api/parcel/registry/countries`)
    },

    listVerified() {
      return c.get(`/api/parcel/registry/verified`)
    },

    save(body) {
      return c.post(`/api/parcel/registry`, body)
    },

    sendToCheck(jiraNumber, id) {
      return c.post(`/api/parcel/registry/send/to/check?jiraNumber=${jiraNumber}&id=${id}`)
    },

    validateExcel(body) {
      return c.post(`/api/parcel/registry/verify/excel`, body)
    },

    verify(id) {
      return c.post(`/api/parcel/registry/verify?id=${id}`)
    }
  }

  parcelTrack = {

    getParcelHistoryByInternalTrack(track) {
      return c.get(`/public/parcel/track/${track}`)
    }
  }

  parcelV2 = {

    create(body) {
      return c.post(`/api/v2/parcel`, body)
    },

    findById(parcelId) {
      return c.get(`/api/v2/parcel/${parcelId}`)
    },

    findIncomingByFilter(status, pageSize, pageNumber, body) {
      return c.post(`/api/v2/parcel/incoming/${status}?pageSize=${pageSize ? pageSize : ''}&pageNumber=${pageNumber ? pageNumber : ''}`, body)
    },

    findOutgoingByFilter(status, pageSize, pageNumber, body) {
      return c.post(`/api/v2/parcel/outgoing/${status}?pageSize=${pageSize ? pageSize : ''}&pageNumber=${pageNumber ? pageNumber : ''}`, body)
    },

    update(parcelId, body) {
      return c.put(`/api/v2/parcel/${parcelId}`, body)
    }
  }

  permission = {

    list() {
      return c.get(`/api/permission`)
    }
  }

  place = {

    delete(id) {
      return c.delete(`/api/dict/place/${id}`)
    },

    getBarCode(id) {
      return c.get(`/api/dict/place/barcode/${id}`)
    },

    listAll() {
      return c.get(`/api/dict/place`)
    },

    save(body) {
      return c.post(`/api/dict/place`, body)
    },

    update(body) {
      return c.put(`/api/dict/place`, body)
    }
  }

  reportQueue = {

    create(body) {
      return c.post(`/api/report`, body)
    },

    delete(reportQueueId) {
      return c.delete(`/api/report/${reportQueueId}`)
    },

    downloadReport(reportQueueId) {
      return c.get(`/api/report/download/${reportQueueId}`)
    },

    read() {
      return c.get(`/api/report`)
    }
  }

  role = {

    delete(roleId) {
      return c.delete(`/api/role?roleId=${roleId}`)
    },

    list() {
      return c.get(`/api/role`)
    },

    listPermissions() {
      return c.get(`/api/role/permissions`)
    },

    save(body) {
      return c.post(`/api/role`, body)
    }
  }

  search = {

    search(search) {
      return c.get(`/api/search?search=${search}`)
    }
  }

  stock = {

    delete(id) {
      return c.delete(`/api/stock/delete?id=${id}`)
    },

    listAll() {
      return c.get(`/api/stock`)
    },

    listCities(regionCode) {
      return c.get(`/api/stock/cities?regionCode=${regionCode}`)
    },

    listRegions() {
      return c.get(`/api/stock/regions`)
    },

    save(body) {
      return c.post(`/api/stock`, body)
    },

    update(body) {
      return c.post(`/api/stock/update`, body)
    }
  }

  trackNumber = {

    getCompany() {
      return c.get(`/api/track/number/company`)
    },

    getResponse(trackNumber, company) {
      return c.get(`/api/track/number?trackNumber=${trackNumber}&company=${company}`)
    }
  }

  transitStock = {

    findParcelByCdekCode(cdekId) {
      return c.get(`/api/transit/stock/find/by?cdekId=${cdekId}`)
    }
  }

  user = {

    block(id) {
      return c.post(`/api/user/block?id=${id}`)
    },

    createPasswordRestoreRequest(id) {
      return c.post(`/api/user/create/restore/password/request?id=${id}`)
    },

    current() {
      return c.get(`/api/user/current`)
    },

    delete(authId) {
      return c.delete(`/api/user?authId=${authId}`)
    },

    getUserById(id) {
      return c.get(`/api/user/byId?id=${id}`)
    },

    getUsersByType(type) {
      return c.get(`/api/user/byType?type=${type}`)
    },

    list() {
      return c.get(`/api/user/roles`)
    },

    save(body) {
      return c.post(`/api/user`, body)
    },

    selectStock(id) {
      return c.post(`/api/user/select/stock/${id}`)
    },

    updateOwnPassword(body) {
      return c.post(`/api/user/update/own/password`, body)
    },

    updatePassword(userId, body) {
      return c.post(`/api/user/update/password?userId=${userId}`, body)
    }
  }
}

export class P_Api {
  auth = {

    login(body) {
      return pc.post(`/api/public/auth/login`, body)
    },

    restorePassword(secret, body) {
      return pc.post(`/api/restore/password?secret=${secret}`, body)
    },

    verifyPasswordRestore(secret) {
      return pc.get(`/api/verify/password/restore?secret=${secret}`)
    }
  }
}
