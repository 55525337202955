<template>
  <!-- The component does not respond to prop color so we use our own class -->
  <v-snackbar
      v-if="globalErrorMessage?.type === 'warning'"
      v-model="dialog"
      :timeout="5000"
      bottom
      right
      text
      content-class="snackbar-warning"
  >
      {{ !!globalErrorMessage?.message ? globalErrorMessage.message : 'error while processing data' }}
  </v-snackbar>

  <v-dialog v-else v-model="dialog" max-width="400px" scrollable persistent>
    <v-card>
      <v-card-text class="pb-0">
        <v-alert type="error" text class="mb-0 d-flex justify-center">
          {{ !!globalErrorMessage?.message ? globalErrorMessage.message : 'error while processing data' }}
        </v-alert>
        <v-card-actions>
          <v-btn block color="primary" text @click="close">OK</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  data: () => ({
    dialog: false,
    message: {}
  }),
  props: {
    globalErrorMessage: {type: Object},
  },
  methods: {
    close() {
      this.dialog = false
    }
  },
  watch: {
    globalErrorMessage(newVal) {
      if (newVal.message) {
        this.dialog = true
      }
    },
    dialog(newVal) {
      if (!newVal) {
        this.$emit('close');
      }
    }
  },
}
</script>

<style scoped lang="scss">
img {
  height: 200px;
  opacity: 0.8;
  margin: 20px;
}

::v-deep .snackbar-warning {
  background: #fff7e1;
  color: var(--v-warning-base);
}
</style>