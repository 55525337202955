import {AuthApi, P_Api} from "./api";
import {NewApi as N_Api} from "./newApi";
import {MshApi as M_Api} from "@/api/http/mshApi";

const Api = new AuthApi()
const PublicApi = new P_Api()
const NewApi = new N_Api()
const MshApi = new M_Api()

export {Api, PublicApi, NewApi, MshApi}
