import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import rules from "@/api/rules";
import { Ripple } from "vuetify/lib/directives";
import { mapGetters } from "vuex";
import "./directives/sandbox";

import "./plugins/vuetify";
import "./components/components";
import "./theme/default.sass";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/scss/style.scss";

import HeroIcon from "vue-heroicons";
import {
  altArrowDownOutline,
  transferHorizontalOutline,
  chatOutline,
  searchOutlined,
  messageConversationOutlined,
  winRarOutlined,
  dialogOutlined,
  smartphoneOutlined,
  uploadOutlined,
  userOutlined,
  signpostOutlined,
  billOutlined,
  dollarOutlined,
  penOutlined,
  printerOutlined,
  settingsOutlined,
  settingsBold,
  squareTopDownOutlined,
  sortVerticalOutlined,
  arrowLeftOutlined,
  fileSendOutlined,
  minusCircleOutlined,
  boxOutlined,
  routingOutlined,
  alignVerticalOutlined,
  trashOutlined,
  weigherOutlined,
  deliveryOutlined,
  arrowRightUpOutlined,
  arrowLeftDownOutlined,
  cityOutlined,
  messageOutlined,
  notebookOutlined,
  warningOutlined,
  squareTopUpBold,
  inboxOutBold,
  usersGroupTwoRoundedBold,
  penBold,
  walletBold,
  chartBold,
  passwordBold,
  trashBold,
  lockKeyholeUnlockedBold,
  widgetBold,
  qrCodeBold,
  addCircleBold,
  arrowRightDownBold,
  arrowRightUpBold,
  busBold,
  userBold,
  mapArrowRotateBold,
  radarBold,
  headPhonesBold,
  garageBold,
  globusBold,
  pointOnMapPerspectiveBold,
  cityBold,
  layerBold,
  threeSquaresBold,
  serverMinimalisticBold,
  filterBold,
  exportBold,
  downloadBold,
  listCrossBold,
  arrowRightBold,
  gpsBold,
  loginBold,
  closeIcon,
  crossBold,
  checkCircleBold,
  closeCircleBold,
  mapPointBold,
  containerBold,
  palletBold,
  sealBold,
} from "./icons/icons";

HeroIcon.add([
  altArrowDownOutline,
  transferHorizontalOutline,
  chatOutline,
  searchOutlined,
  messageConversationOutlined,
  winRarOutlined,
  dialogOutlined,
  smartphoneOutlined,
  uploadOutlined,
  userOutlined,
  signpostOutlined,
  billOutlined,
  dollarOutlined,
  penOutlined,
  printerOutlined,
  trashOutlined,
  squareTopDownOutlined,
  settingsOutlined,
  settingsBold,
  squareTopDownOutlined,
  sortVerticalOutlined,
  arrowLeftOutlined,
  fileSendOutlined,
  minusCircleOutlined,
  boxOutlined,
  routingOutlined,
  alignVerticalOutlined,
  weigherOutlined,
  deliveryOutlined,
  arrowRightUpOutlined,
  arrowLeftDownOutlined,
  cityOutlined,
  messageOutlined,
  notebookOutlined,
  warningOutlined,

  squareTopUpBold,
  inboxOutBold,
  usersGroupTwoRoundedBold,
  penBold,
  walletBold,
  chartBold,
  passwordBold,
  trashBold,
  lockKeyholeUnlockedBold,
  widgetBold,
  qrCodeBold,
  addCircleBold,
  arrowRightDownBold,
  arrowRightUpBold,
  busBold,
  userBold,
  mapArrowRotateBold,
  radarBold,
  headPhonesBold,
  garageBold,
  globusBold,
  pointOnMapPerspectiveBold,
  cityBold,
  layerBold,
  threeSquaresBold,
  serverMinimalisticBold,
  crossBold,
  filterBold,
  exportBold,
  checkCircleBold,
  closeCircleBold,
  downloadBold,
  listCrossBold,
  arrowRightBold,
  gpsBold,
  loginBold,
  containerBold,
  sealBold,

  closeIcon,
  crossBold,
  checkCircleBold,
  closeCircleBold,
  mapPointBold,
  palletBold,
]);
Vue.use(HeroIcon);

Vue.config.productionTip = false;
Vue.prototype.$rule = rules;

window.app = new Vue({
  router,
  store,
  vuetify,
  mapGetters,
  directives: {
    Ripple,
  },
  render: (h) => h(App),
}).$mount("#app");
