<template>
  <!--  <v-app-bar app color="white" height="74" flat outlined>-->
  <!--    <v-img src="/static/images/logo.jpg" height="16px" contain/>-->
  <!--  </v-app-bar>-->
  <v-app-bar app height="74" flat outlined>
    <div class="page-wrapper d-flex align-center">
      <div>
        <router-link to="/">
          <v-img src="/static/images/logo.svg" height="16px" width="100px" contain/>
        </router-link>
      </div>
      <div  class="ml-4" style="width: 150px">
        <v-autocomplete
            v-model="selectedStock"
            label="stock"
            :items="items"
            item-text="name"
            item-value="id"
            hide-selected
            dense
            hide-details
            @input="setStock"
        outlined/>
      </div>
      <div style="width:400px" class="px-6 d-flex align-center">
        <v-text-field
            dense
            hide-details
            label="Search"
            outlined
            v-model="search"
            @keyup.enter="searchItems"
        >
          <template v-slot:append-outer class="ma-0">
            <div class="search-button" @click="searchItems">
              <heroicon name="searchOutlined" fill="white" width="20" height="20"/>
            </div>
          </template>
        </v-text-field>
      </div>
      <v-spacer/>
      {{ username }}
      <v-btn small icon color="primary" class="" @click="$router.push('/user/info')">
        <v-icon small>settings</v-icon>
      </v-btn>
      <v-btn class="ml-5" color="red" text @click="logout">
        log out
      </v-btn>
    </div>
  </v-app-bar>
</template>
<script>
import {mapGetters, mapActions, mapState} from "vuex";
import {use} from "chai";
import {Api} from "../api/http";

export default {
  name: "AppToolbar",
  components: {},
  data() {
    return {
      items: [],
      model: {},
      search: "",
      selectedStock: null
    };
  },
  computed: {
    ...mapGetters(['username']),
    ...mapGetters(['user']),
    ...mapActions(['setGlobalErrorMessage']),
    ...mapState(['currentBranch']),

  },
  watch: {
    user(newVal) {
      console.log(newVal)
      this.items = newVal.stocks
      this.selectedStock = newVal.selectedStockId
    }
  },
  methods: {
    setStock() {
     if (!!this.selectedStock) {
       Api.user.selectStock(this.selectedStock)
           .then(() => window.location.reload())
       this.$store.dispatch('selectBranch', this.selectedStock)
     }
    },
    stocks() {
      if (!!this.user) {
        console.log(this.user)
        this.items = this.user.stocks
        this.selectedStock = this.user.selectedStockId
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    changeTheme() {
      localStorage.setItem('dark-theme', this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
    },
    selectBranch(branch) {
      this.model = branch
      Api.user.selectStock(branch.id)
          .then(() => window.location.reload())
      this.$store.dispatch('selectBranch', branch)
    },
    getBranch() {
      this.model = JSON.parse(localStorage.getItem('branch'))
    },
    searchItems() {
      if (!!this.search) {
        this.$store.dispatch('selectSearchWord', this.search)
        this.redirect()
      }
    },
    clearWord() {
      this.search = ""
      this.$store.dispatch('clearSearchWord', "")
    },
    redirect() {
      window.location = "/result-search/" + this.search
      this.search = ""
    },
  },
  mounted() {
    this.getBranch();
    this.stocks();
    // this.$nextTick(() => {
    //   if (!this.model || !this.model.id) {
    //     if (this.items.length > 0) {
    //       this.selectBranch(this.items[0]);
    //     }
    //   }
    // });
  }
};
</script>
<style scoped>
.v-input:last-child {
  margin-bottom: 0px;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;
  width: 120px;
  color: #008233;
  font-size: 20px;
  padding: 0 10px;
}

.role-text {
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
}

.role-font-size {
  font-size: 16px;
  line-height: 20px;
}

.search-button {
  background-color: #008233;
  width: 40px;
  height: 40px;
  margin-top: -8px;
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
</style>

