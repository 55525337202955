import {Http} from "./http";

const NEW_BASE_URL = process.env.VUE_APP_NEW_API_URL;

const c = new Http(NEW_BASE_URL);

export class NewApi {
    get cli() {
        return c;
    }

    calculator = {
        getCities() {
            return c.get("/get-cities");
        },

        getCurrencies() {
            return c.get("/get-currencies");
        },

        calculateDelivery(body) {
            return c.post("/calculate-delivery", body);
        },

        calculateDeliveryV2(body) {
            return c.post("/calculate-delivery-v2", body);
        },
    }

    parcel = {
        getParcel(id) {
            return c.post("/parcel/", {id});
        },

        setParcelDimensions(id, dimensions) {
            return c.post("/parcel/update_dimensions", {id, ...dimensions});
        },
    }

    exports = {
        exportReport(body) {
            return c.cli.post("/export/", body, {responseType: 'blob'});
        },

        exportAllReports() {
            return c.cli.post("/export/all", {}, {responseType: 'blob'});
        },

        exportFinanceReport(body) {
            return c.cli.post("/export/finance", body, {responseType: 'blob'});
        },

        exportOldFinanceReport(body) {
            return c.cli.post("/export/old-finance", body, {responseType: 'blob'});
        },
    }

    hs = {
        search(product) {
            return c.post("/hs/search", {product});
        },

        buildTree(code) {
            return c.post("/hs/build-tree", {code});
        },

        getHsDirectory() {
            return c.get("/hs/directory");
        },

        saveEditedCard(editedCard) {
            return c.post("/hs/edit-card", editedCard);
        },

        createCard(newCard) {
            return c.post("/hs/create-card", newCard);
        },

        searchChapter(chapter) {
            return c.get("/hs/search-chapter?chapter=" + chapter);
        }
    }

    product = {
        findByIdsRange(idsRange) {
            return c.get(`/product/find-by-ids?ids=${idsRange}`);
        },

        getExpected() {
            return c.get("/product/in-expected");
        }
    }

    consolidations = {
        getDubai() {
            return c.get("/consolidation/get-dubai");
        },

        setTrackCode(id, body) {
            return c.post("/consolidation/set-track-code", {
                id,
                ...body
            });
        }
    }
}
