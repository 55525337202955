<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public && !loading">
      <v-app id="inspire">
        <app-toolbar />
        <v-main class="page-wrapper mt-8">
          <v-row class="pa-0">
            <v-col cols="2" class="pa-0 pt-2">
              <app-drawer />
            </v-col>
            <v-col cols="10" class="pa-0 pl-4">
              <router-view />
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="!loading">
      <v-app-bar app height="74" flat outlined>
        <v-img src="/static/images/logo.svg" height="16px" contain />
      </v-app-bar>
      <v-app class="primary page-wrapper">
        <keep-alive>
          <router-view :key="$route.fullpath" />
        </keep-alive>
      </v-app>
    </template>
    <error-dialog
      :global-error-dialog="globalErrorDialog"
      :global-error-message="globalErrorMessage"
      @click="setGlobalErrorMessage"
    />
    <loading v-if="loading" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AppDrawer from '@/components/AppDrawer';
import AppToolbar from '@/components/AppToolbar';
import Loading from '@/views/Loading';
import { Api } from './api/http';

export default {
  components: {
    Loading,
    AppDrawer,
    AppToolbar,
  },
  data: () => ({
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
  }),
  computed: {
    ...mapGetters([
      'globalErrorDialog',
      'globalErrorMessage',
      'errorMessages',
      'loading',
    ]),
  },
  methods: {
    ...mapActions(['setGlobalErrorMessage', 'init']),
    handleKeydown(event) {
      const isMac = this.isMacOS();
      const isCmdF1 = isMac && event.metaKey && event.key === 'F1';
      const isCtrlF1 = !isMac && event.ctrlKey && event.key === 'F1';

      if (isCmdF1 || isCtrlF1) {
        event.preventDefault();
        if (this.$route.path !== '/incoming-parcels') {
          this.$router.push('/incoming-parcels');
        }
      }
    },
    isMacOS() {
      if (navigator.userAgentData) {
        return navigator.userAgentData.platform === 'macOS';
      }
      return /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  created() {
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    document.getElementsByTagName('head')[0].appendChild(link);

    this.width = document.body.clientWidth;
    this.init();

    window.getApp = this;

    Api.cli.set401Interceptor(() => {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    });

    Api.cli.setGlobalErrorHandler((e) =>
      this.setGlobalErrorMessage(e.d.message)
    );
  },
};
</script>
