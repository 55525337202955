import store from '../store/store';
import { Api, PublicApi } from '../api/http';

export default [
  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/404',
    },
  },
  {
    path: '/404',
    meta: {
      public: true,
    },
    name: 'NotFound',
    component: () => import(`@/views/error/NotFound.vue`),
  },
  {
    path: '/403',
    meta: { public: true },
    name: 'AccessDenied',
    component: () => import(`@/views/error/Deny.vue`),
  },
  {
    path: '/500',
    meta: { public: true },
    name: 'ServerError',
    component: () => import(`@/views/error/Error.vue`),
  },
  {
    path: '/login',
    meta: { public: true },
    name: 'Login',
    component: () => import(`@/views/Login.vue`),
  },
  {
    path: '/password/restore/:secret',
    meta: { public: true },
    name: 'restorePassword',
    component: () => import(`@/views/RestorePassword.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.secret) {
        PublicApi.auth.verifyPasswordRestore(routeTo.params.secret).then(
          (r) => {
            next();
            store.dispatch('setPasswordRestore', {
              user: r,
              secret: routeTo.params.secret,
            });
          },
          () => next({ name: 'NotFound' })
        );
      } else {
        next({ name: 'NotFound' });
      }
    },
  },
  {
    path: '/',
    meta: {},
    name: 'Root',
    redirect: {
      name: 'Home',
    },
  },
  {
    path: '/home',
    meta: { breadcrumb: true },
    name: 'Home',
    component: () => import(`@/views/Home.vue`),
  },
  {
    path: '/permission',
    meta: { breadcrumb: true },
    name: 'permission',
    component: () => import(`@/views/Permission.vue`),
  },
  {
    path: '/roles',
    meta: { breadcrumb: true },
    name: 'roles',
    component: () => import(`@/views/Roles.vue`),
  },
  {
    path: '/users',
    meta: { breadcrumb: true },
    name: 'users',
    component: () => import(`@/views/Users/index.vue`),
  },
  {
    path: '/user/info',
    meta: { breadcrumb: true },
    name: 'usersInfo',
    component: () => import(`@/views/Users/UsersInfo.vue`),
  },
  {
    path: '/user/:id/parcels',
    meta: { breadcrumb: true },
    name: 'user-parcels',
    component: () => import(`@/views/User/Parcels/index.vue`),
  },
  {
    path: '/user/:id/edit',
    meta: { breadcrumb: true },
    name: 'user-edit',
    component: () => import(`@/views/User/Edit.vue`),
  },
  {
    path: '/user/:id/parcel/:parcelID',
    meta: { breadcrumb: true, fullPage: true },
    name: 'user-solo-parcel',
    component: () => import(`@/views/User/Parcels/SoloParcel/index.vue`),
  },
  {
    path: '/country',
    meta: { breadcrumb: true },
    name: 'country',
    component: () => import(`@/views/dict/Country.vue`),
  },
  {
    path: '/track-number',
    meta: { breadcrumb: true },
    name: 'track-number',
    component: () => import(`@/views/TrackNumber.vue`),
  },
  {
    path: '/incoming-parcels',
    meta: { breadcrumb: true },
    name: 'incoming-parcels',
    component: () => import(`@/views/IncomingParcels/index.vue`),
  },
  {
    path: '/incoming-parcels/:id/success',
    meta: { breadcrumb: true },
    name: 'incoming-parcel-success',
    component: () => import(`@/views/IncomingParcels/SuccessParcel.vue`),
  },
  {
    path: '/outgoing-parcels',
    meta: { breadcrumb: true },
    name: 'outgoing-parcels',
    component: () => import(`@/views/OutgoingParcels/index`),
  },
  {
    path: '/stock',
    meta: { breadcrumb: true },
    name: 'stock',
    component: () => import(`@/views/Stock.vue`),
  },
  {
    path: '/consignments',
    meta: { breadcrumb: true },
    name: 'consignments',
    component: () => import(`@/views/Consignments/index.vue`),
  },
  {
    path: '/consignments/pallets-containers/:id',
    meta: { breadcrumb: true },
    name: 'pallets-containers',
    component: () =>
      import(`@/views/Consignments/PalletsAndContainers/index.vue`),
  },
  {
    path: '/consignments/pallets-containers/:id/formatting/:type',
    meta: { breadcrumb: true },
    name: 'formatting-process',
    component: () =>
      import(
        `@/views/Consignments/PalletsAndContainers/FormattingProcess/index.vue`
      ),
  },
  {
    path: '/consignments/pallets-containers/:id/products-in-container/:boxId',
    meta: { breadcrumb: true },
    name: 'products-in-container',
    component: () =>
      import(
        `@/views/Consignments/PalletsAndContainers/AllProductsInContainer.vue`
      ),
  },
  {
    path: '/consignments/pallets-containers/:id/container-in-pallet/:palletId',
    meta: { breadcrumb: true },
    name: 'container-in-pallet',
    component: () =>
      import(
        `@/views/Consignments/PalletsAndContainers/AllContainersInPallet.vue`
      ),
  },
  {
    path: '/address-storage',
    meta: { breadcrumb: true },
    name: 'addressStorage',
    component: () => import(`@/views/AddressStorage/index.vue`),
  },
  {
    path: '/parcels/create-parcel',
    meta: { fullPage: true },
    name: 'AddNewParcelSinglePageMode',
    component: () =>
      import(`@/views/CreateParcel/AddNewParcelSinglePageMode.vue`),
  },
  {
    path: '/parcels/client/:id',
    meta: { fullPage: true },
    name: 'AddNewParcel',
    component: () => import(`@/views/CreateParcel/AddNewParcelFirstStep.vue`),
  },
  {
    path: '/parcels/packs/:id',
    meta: { fullPage: true },
    name: 'ParcelAddress',
    component: () => import(`@/views/CreateParcel/AddNewParcelSecondStep.vue`),
  },
  {
    path: '/parcels/recipient/:id',
    meta: { fullPage: true },
    name: 'ParcelRecipient',
    component: () => import(`@/views/CreateParcel/AddNewParcelThirdStep.vue`),
  },
  {
    path: '/api',
    meta: { breadcrumb: true },
    name: 'user-api',
    component: () => import(`@/views/UsersApi.vue`),
  },
  {
    path: '/cdek-tariff',
    meta: { breadcrumb: true },
    name: 'cdekTariff',
    component: () => import(`@/views/CdekTariff.vue`),
  },
  {
    path: '/parcel/:id',
    meta: { fullPage: true },
    name: 'Parcel',
    component: () => import(`@/views/Parcel.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        Api.parcel.getById(routeTo.params.id).then(
          (r) => {
            store.dispatch('setCurrentParcel', r);
            next();
          },
          () => next({ name: 'NotFound' })
        );
      }
    },
  },
  {
    path: '/outgoing-parcels/collect/:id',
    meta: { breadcrumb: true },
    name: 'outgoing-parcels-assembly',
    component: () => import(`@/views/OutgoingParcels/ProcessParcel.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        Api.parcel.getById(routeTo.params.id).then(
          (r) => {
            store.dispatch('setCurrentParcel', r);
            next();
          },
          () => next({ name: 'NotFound' })
        );
      }
    },
  },
  {
    path: '/incoming-parcels/register/:track',
    meta: { fullPage: true },
    name: 'RegisterIncoming',
    component: () => import(`@/views/IncomingParcels/Register.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.track) {
        Api.parcel.getByTrack(routeTo.params.track).then(
          (r) => {
            store.dispatch('setCurrentParcel', r);
            next();
          },
          () => next({ name: 'NotFound' })
        );
      }
    },
  },
  {
    path: '/result-search/:search',
    meta: { breadcrumb: true },
    name: 'result-search',
    component: () => import(`@/views/ResultSearch/index.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.search) {
        Api.search.search(routeTo.params.search).then(
          (r) => {
            store.dispatch('setSearchResult', r);
            next();
          },
          () => next({ name: 'NotFound' })
        );
      }
    },
  },
  {
    path: '/parcel/by/track/:track',
    meta: { fullPage: true },
    name: 'ParcelInfo',
    component: () => import(`@/views/Parcel.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.track) {
        Api.parcel.getByTrack(routeTo.params.track).then(
          (r) => {
            store.dispatch('setCurrentParcel', r);
            next();
          },
          () => next({ name: 'NotFound' })
        );
      }
    },
  },
  {
    path: '/parcel/confirm-order/:id',
    meta: { breadcrumb: true, fullPage: true, props: true },
    name: 'confirm-order',
    component: () => import(`@/components/ConfirmOrder.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        Api.parcel.getById(routeTo.params.id).then(
          (r) => {
            store.dispatch('setCurrentParcel', r);
            next();
          },
          () => next({ name: 'NotFound' })
        );
      }
    },
  },
  {
    path: '/business-tariff',
    meta: { breadcrumb: true },
    name: 'business-tariff',
    component: () => import(`@/views/BusinessTariff.vue`),
  },
  {
    path: '/delivery-services',
    meta: { breadcrumb: true },
    name: 'delivery-services',
    component: () => import(`@/views/DeliveryServices.vue`),
  },
  {
    path: '/parcel-registry',
    meta: { breadcrumb: true },
    name: 'parcel-registry',
    component: () => import(`@/views/Registry/index.vue`),
  },
  {
    path: '/registry/items/:id',
    meta: { breadcrumb: true },
    name: 'items-of-registry',
    component: () => import(`@/views/Registry/ItemsOfRegistry/index.vue`),
  },
  {
    path: '/invoice',
    meta: { breadcrumb: true },
    name: 'invoice',
    component: () => import(`@/views/Invoices/index.vue`),
  },
  {
    path: '/business-users',
    meta: { breadcrumb: true },
    name: 'business-users',
    component: () => import(`@/views/BusinessUsers.vue`),
  },
  {
    path: '/pallet',
    meta: { breadcrumb: true },
    name: 'pallet',
    component: () => import(`@/views/dict/Pallet.vue`),
  },
  {
    path: '/place',
    meta: { breadcrumb: true },
    name: 'place',
    component: () => import(`@/views/dict/Place.vue`),
  },
  {
    path: '/seal',
    meta: { breadcrumb: true },
    name: 'seal',
    component: () => import(`@/views/dict/Seal.vue`),
  },
  {
    path: '/container',
    meta: { breadcrumb: true },
    name: 'container',
    component: () => import(`@/views/dict/Container.vue`),
  },
  {
    path: '/dubai',
    meta: { breadcrumb: true },
    name: 'dubai',
    component: () => import(`@/views/dubai/ScanQr.vue`),
  },
  {
    path: '/dubai/consignments',
    meta: { breadcrumb: true },
    name: 'dubai-consignments',
    component: () => import(`@/views/dubai/consignments/index.vue`),
  },
  {
    path: '/dubai/consignments/containers',
    meta: { breadcrumb: true },
    name: 'dubai-containers',
    component: () =>
      import(`@/views/dubai/consignments/PalletsAndContainers/index.vue`),
  },
  {
    path: '/map-pvz',
    meta: { public: true },
    name: 'map',
    component: () => import(`@/views/Map.vue`),
  },
  {
    path: '/track',
    meta: { breadcrumb: true },
    name: 'trackParcel',
    component: () => import(`@/views/TrackParcel.vue`),
  },
  {
    path: '/sandbox/report',
    name: 'report',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/ReportView.vue'),
  },
  {
    path: '/sandbox/new-tariff',
    name: 'newTariff',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/NewTariff.vue'),
  },
  {
    path: '/sandbox/edit-parcel',
    name: 'editParcel',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/EditParcel.vue'),
  },
  {
    path: '/sandbox/export',
    name: 'export',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/Export.vue'),
  },
  {
    path: '/sandbox/export-logistic',
    name: 'export-logistic',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/ExportLogistic.vue'),
  },
  {
    path: '/sandbox/hs-tree',
    name: 'hs-tree',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/HSTree.vue'),
  },
  {
    path: '/sandbox/hs-directory',
    name: 'hs-directory',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/HSDirectory.vue'),
  },
  {
    path: '/sandbox/hs-bulk',
    name: 'hs-bulk',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/HSBulk.vue'),
  },
  {
    path: '/sandbox/consolidations',
    name: 'consolidations',
    meta: { breadcrumb: true },
    component: () => import('@/views/sandbox/Consolidations.vue'),
  },
];
