import { Api } from "../api/http";
export default {
  state() {
    return {
      model: {
        recipient: {
          fullName: "",
          passportSerial: "",
          passportNumber: "",
          dateOfIssue: "",
          dateOfBirth: "",
          authority: "",
          tin: "",
          phone: "",
          email: "",
        },
      },
      recipientAddress: "",
      zipCode: "",
    };
  },
  getters: {
    getModel: (state) => state.model,
  },
  mutations: {
    resetRecipientData(state) {
      // state.model.pvz will not be reset because we set it on the zero step
      state.model.recipient = {
        fullName: "",
        passportSerial: "",
        passportNumber: "",
        dateOfIssue: "",
        dateOfBirth: "",
        authority: "",
        tin: "",
        phone: "",
        email: "",
      };

      state.model.recipientAddress = "";
      state.model.zipCode = "";
    },
    setUserId(state, userId) {
      state.model.userId = userId;
    },
    setParcelName(state, name) {
      state.model.name = name;
    },
    removeProduct(state, delElement) {
      state.model.cargoSpaces[delElement.cargoIndex].products.splice(
        delElement.index,
        1
      );
    },
    addProduct(state, i) {
      if (!Array.isArray(state.model.cargoSpaces[i].products)) {
        state.model.cargoSpaces[i].products = [];
      }
      state.model.cargoSpaces[i].products.push({
        name: "",
        link: "",
        type: "",
        brand: "",
        info: "",
        quantity: 0,
        cost: 0,
      });
    },
    setModel(state, val) {
      state.model = { ...state.model, ...val };
    },
  },
  actions: {
    async sentToServer({ state }) {
      try {
        const response = await Api.addNewParcel.createParcelSingleStep(
          state.model
        );
        return response;
      } catch (e) {
        throw e;
      }
    },
  },
};
